<template>
    <div class="form">
        <h1>Покупка {{fundName}}</h1>
        <div class="form__body">
            <bad-docs v-if="!isDocsAllright" :documents-required="documentsRequired" :fund-id="fundId" :is-have-permissions="isHavePermissions" />
            <purchase-requisition
                    v-else
                    :user="user"
                    :timer="timer"
                    :show-checking="showChecking"
                    :share-cost="fundPrice"
                    :fund-account-number="fundLsNumber"
                    :accounts-list="accountsList"
                    :bank-account="fundAccountNumber"
                    :fund-id="fundId"
                    :fund-status="fundStatus"
                    :have-purchases="havePurchases"
                    :du-target="fileDu"
                    @submit="onSibmit"
                    @verified="onVerifiedCode"
                    @sendAgain="sendAgain"
                    @timer="timerIsOver"
                    @dataChanged="$emit('dataChanged')"
                    @downloadDU="$emit('downloadDU', $event)"
            />
        </div>
    </div>
</template>

<script>
    import BadDocs from "./BadDocs";
    import PurchaseRequisition from "./PurchaseRequisition";
    export default {
        name: "BuyInvestmentSharesPage",
        components: {PurchaseRequisition, BadDocs},
        props: {
            isHavePermissions: Object,
            havePurchases: Boolean,
            timer: {
                type: Boolean,
                default: false
            },
            showChecking: {
                type: Boolean,
                default: false
            },
            isDocsAllright: {
                type: Boolean,
                required: true
            },
            documentsRequired: {
                type: Array,
                required: true
            },
            user: Object,
            accountsList: Array,
            fundName: String,
            fundId: [String, Number],
            fundPrice: [String, Number],
            fundLsNumber: [String, Number],
            fundLsRequestNumber: [String, Number],
            fundAccountNumber: [String, Number],
            fundStatus: [String, Number],
            fileDu: [Object, null]
        },
        methods: {
            onSibmit() {
                this.$emit('submit')
            },
            onVerifiedCode(code) {
                this.$emit('verified', code)
            },
            sendAgain() {
                this.$emit('sendAgain')
            },
            timerIsOver(timer) {
                this.$emit('timer', timer)
            }
        }
    }
</script>

<style scoped lang="scss">
    .form {
        text-align: left;

        & h1 {
            margin-bottom: 32px;
        }

        &__body {
            display: flex;
            flex-direction: column;
            row-gap: 32px;
        }
    }
</style>