<template>
  <buy-investment-shares-page
    v-if="!is_loading"
    :isDocsAllright="is_documents_filledOut"
    :documents-required="documents_required"
    :user="getUSER"
    :timer="timer"
    :show-checking="showChecking"
    :accounts-list="getBANK_ACCOUNTS"
    :fund-name="getFundDetales.name"
    :fund-id="getFundDetales.id"
    :fund-price="getFundDetales.price"
    :fund-ls-number="getFundDetales.ls_number"
    :fund-ls-request-number="getFundDetales.ls_request_number"
    :fund-account-number="getFundDetales.bank_account_id"
    :fund-status="getFundDetales.status_id"
    :have-purchases="getFundDetales.isPrimary"
    :is-have-permissions="isHavePermissions"
    :file-du="getFundDetales.file_rules_ki || getFundDetales.file_rules_not_ki"
    @downloadDU="downloadDU"
    @submit="onSibmit"
    @verified="onVerifiedCode"
    @sendAgain="onSibmit"
    @timer="timerIsOver"
    @dataChanged="hideSMS"
  />
  <div v-else style="display: block; height: 500px;">

  </div>
</template>

<script>
import BuyInvestmentSharesPage from "../../../components/pages/cabinet/Funds/BuyInvestmentSharesPage";
import { mapGetters, mapActions } from "vuex";
import RWMSendingDocumentsMessageMixin from "../../../components/mixins/RWMSendingDocumentsMessageMixin";

export default {
  name: "BuyInvestmentShares",
  components: { BuyInvestmentSharesPage },
  mixins: [RWMSendingDocumentsMessageMixin],
  watch: {
    async fondChanged() {
      if (this.$route.params.fundId == this.changedFondId) {
        this.GET_BANK_ACCOUNTS();
        await this.GET_FUND_DETALES({id: this.$route.params.fundId, not_check_ci: true});
        this.nextDocument()
      }
    },
    async docChanged() {
        this.GET_BANK_ACCOUNTS();
        await this.GET_FUND_DETALES({id: this.$route.params.fundId, not_check_ci: true});
        this.nextDocument()
    },
  },
  computed: {
    ...mapGetters("errorModule", ["getERROR"]),
    ...mapGetters("AuthModule", ["getUSER"]),
    ...mapGetters("bankAccModule", ["getBANK_ACCOUNTS"]),
    ...mapGetters("FundsModule", ["getFundDetales"]),
    ...mapGetters("AutoUpdateModule", ["fondChanged", "changedFondId", "docChanged"]),
    isHavePermissions() {
      return {fatca: this.getFundDetales?.fatca_status == 3, pod_ft: this.getFundDetales?.pod_ft_status == 3}
    },
  },
  data() {
    return {
      timer: false,
      is_documents_filledOut: false,
      documents_required: [],
      showChecking: false,
      is_loading: false,
    };
  },
  methods: {
    ...mapActions("smsModule", ["REQUEST_SMS", "CONFIRM_SMS"]),
    ...mapActions("bankAccModule", ["GET_BANK_ACCOUNTS"]),
    ...mapActions("FundsModule", ["GET_FUND_DETALES"]),
    ...mapActions("DocumentsModule", ["CREATE_DOCUMENT"]),
    ...mapActions("regModule", ["DOWNLOAD_FILE"]),

    getDocStatusText(status_id) {
      switch (status_id) {
        case 1: return "Отправлено";
        case 2: return "Отклонено";
        case 3: return "Обработано";
        case 4: return "Требует актуализацию";
        case 5: return "Архив";
        case 6: return "Принято";
        default: return "";
      }
    },

    downloadDU(file) {
      this.DOWNLOAD_FILE({name: file.origin_name, id: file.id})
    },
    hideSMS() {
      this.showChecking = false;
    },
    onSibmit() {
      this.loading = true;
      this.REQUEST_SMS({ phone: this.getUSER.email, action: 'buy_shares' }).then((success) => {
        if (success) {
          this.showChecking = true;
          this.timer = true;
          this.loading = false;
        } else {
          this.$toast.error(`${this.getERROR}`);
        }
      });
    },
    onVerifiedCode(data) {
      let { code, form } = data;
      this.CONFIRM_SMS({ phone: this.getUSER.email, key: code }).then(
        (success) => {
          if (success) {
            this.CREATE_DOCUMENT({
              alias: "appl-purchase_invest_shares",
              data: form,
            }).then((responce) => {
              if (responce) {
                this.show_sending_documents_message();
                setTimeout(() => {
                  this.$router.push("/cabinet/investment");
                }, 1000);
              }
            });
          } else {
            this.$toast.error(`${this.getERROR}`);
          }
        }
      );
    },
    timerIsOver(timer) {
      this.timer = timer;
    },
    nextDocument() {
      document.getElementsByClassName('cabinet-wrapper').item(0).scroll(-100,0)
      let common_docs = [];

      // POD_FT
      let doc_status = this.getFundDetales.pod_ft_status;
      if (doc_status != 1 && doc_status != 3) {
        this.$router.push(`/cabinet/documents/forms/pod-ft?next=` + this.$route.params.fundId)
        return
      }
      common_docs.push(doc_status)

      // FATCA
      doc_status = this.getFundDetales.fatca_status;
      if (doc_status != 1 && doc_status != 3) {
        this.$router.push(`/cabinet/documents/forms/fatca?next=` + this.$route.params.fundId)
        return
      }
      common_docs.push(doc_status)

      // KI
      if (this.getFundDetales.file_rules_ki !== null) {
        doc_status = this.getFundDetales.appl_ci_status
        if(this.getFundDetales.appl_ci_status == null) {
          this.$router.push(`/cabinet/documents/forms/ki?next=` + this.$route.params.fundId)
          return
        }
        common_docs.push(doc_status)
      }

      // registered-person
      if (typeof this.getFundDetales.ankets?.find(el => el.type_id === 4 && (el.status_id === 1 || el.status_id === 3)) === "undefined") {
        this.$router.push("/cabinet/documents/forms/registered-person?to=" + this.$route.params.fundId + "&next=" + this.$route.params.fundId)
        return
      }

      // open-personal-account
      if (typeof this.getFundDetales.ankets?.find(el => el.type_id === 5 && (el.status_id === 1 || el.status_id === 3)) === "undefined") {
        this.$router.push("/cabinet/documents/forms/open-personal-account?to=" + this.$route.params.fundId + "&next=" + this.$route.params.fundId)
        return
      }

      if (typeof common_docs.find(item => item != 3) !== 'undefined')
      {
        this.$router.push("/cabinet/documents");
        return;
      }

      let filteredFundDetales = this.getFundDetales.ankets?.filter((fund) => fund.status_id > 0 && fund.status_id !== 9)
      if (this.getFundDetales?.change_pers_data_status > 0 && this.getFundDetales?.change_pers_data_status < 9)
        filteredFundDetales = [
          {
            name: "Заявление об изменении данных анкеты зарегистрированного физического лица",
            status: this.getDocStatusText(this.getFundDetales?.change_pers_data_status),
            status_id: this.getFundDetales?.change_pers_data_status,
            type_id: 8,
            id: 346,
          },
          ...filteredFundDetales
        ]

      if (typeof filteredFundDetales.find(item => item.status_id != 3) != 'undefined') {
        this.documents_required = [...filteredFundDetales];
        this.is_documents_filledOut = false;
      }
      else
      {
        this.documents_required = [];
        this.is_documents_filledOut = true;
      }
    }
  },
  async created() {
    this.is_loading = true;
    this.GET_BANK_ACCOUNTS();
    await this.GET_FUND_DETALES({id: this.$route.params.fundId, not_check_ci: true});
    this.nextDocument()
    this.is_loading = false;
  },
};
</script>

<style scoped lang="scss"></style>
