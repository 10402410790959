<template>
    <div class="bad-docs">
        <h3>Для приобретения паев фонда Вам необходимо подать следующие документы:</h3>

        <ul class="bad-docs-list" v-if="isHavePermissions.fatca && isHavePermissions.pod_ft">
            <li v-for="(document, index) in documentsRequired" :key="document.id">
                <p class="anketa">{{index + 1}}. {{document.name}} </p>
                <p class="anketa">
                    <span v-if="document.status" class="bad-docs-list_status">Статус:</span> <span :class="['bad-docs-list_status-value', {'done' : document.status_id===3}]">{{document.status}}</span>
                </p>
                <p class="anketa" v-if="document.comment_reject">
                    <span class="bad-docs-list_status">Комментарий администратора:</span> <span :class="['bad-docs-list_status-value']">{{document.comment_reject}}</span>
                </p>
                <div class="row">
                    <div class="col-3">
                        <rwm-button outlined width="100%"
                                    content-width="100%"
                                    :disabled="document.status_id===3 || document.status_id===1" class="button-size" @click="routingTo(document.type_id)">
                            <template slot="prepend-img">
                                <v-icon :hovering="false" style="margin-right: 15px">note-pencil</v-icon>
                            </template>
                            {{document.name}}
                        </rwm-button>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="bad-docs-list">
            <li v-if="!isHavePermissions.fatca">
                <p class="anketa">Анкета FATCA</p>
            </li>
            <li v-if="!isHavePermissions.pod_ft">
                <p class="anketa">Анкета ПОД/ФТ</p>
            </li>
        </ul>
        <p class="anketa">
            Заявку на приобретение паев можно подать после утверждения предыдущих документов (данные документы должны перейти в статус «Обработано»)
        </p>
    </div>
</template>

<script>
    import RwmButton from "../../../RWMButton/RwmButton";
    import VIcon from "../../../icons/VIcon";
    export default {
        name: "BadDocs",
        components: {VIcon, RwmButton},
        props: {
            documentsRequired: {
                type: Array,
                required: true,
                fundId: [String, Number]
            },
            isHavePermissions: Object
        },
        methods: {
            routingTo(type) {
                let rout = type === 4 ? "/cabinet/documents/forms/registered-person" :
                    type === 5 ? "/cabinet/documents/forms/open-personal-account" :
                        type === 8 ? "/cabinet/documents/forms/change-personal-data" : "/cabinet/investment"
                this.$router.push({path: rout, query: {to: `${this.$route.params.fundId}`}})
            }
        }
    }
</script>

<style scoped lang="scss">
    .bad-docs {
        display: flex;
        flex-direction: column;
        row-gap: 34px;
        &-list {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            row-gap: 44px;
            &_status {
                &-value {
                    font-weight: bold;
                    color: $RWM-red;
                    &.done {
                        color: $RWM-green;
                    }
                }
            }
            & li {
                display: flex;
                flex-direction: column;
                row-gap: 18px;
            }
            & .button-size {
                @media screen and (min-width: 961px) {
                    min-width: 370px;
                }
            }
        }
    }

    .button-container {
        display: flex;
        margin: 0;
        @media screen and (min-width: 961px) {
            min-width: 550px;
            max-width: 550px;
        }
    }

</style>